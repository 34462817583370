<template>
    <div class="fees-by-product w-full d-flex">
        <AonCol class="aon-col-12 px-0">
            <AonCard class="chart-holder" title="Upcoming Fees By Product">
                <div class="card-content">
                    <div id="feesStackedBarChart" :style="chartHeightStyle"></div>
                </div>
                <AonCoverLoading
                    :loading="props.data.length === 0 && !props.loading"
                    :show-spinner="false"
                    :title="`No Fees By Product`"
                    :message="`No fees were found for ${entityStore.entity?.name} between ${portfolioManagerStore.dateRange[0]} and ${portfolioManagerStore.dateRange[1]}`"
                />
            </AonCard>
        </AonCol>
    </div>
</template>

<script setup>
import { watch, inject, computed, nextTick, ref } from 'vue'
import { useEntityStore, usePortfolioManagerStore } from '@/stores'

import * as am5 from '@amcharts/amcharts5'
import { useStackedBarChart } from '@/composables/charts/stackedBarChart'
import { config } from '@/config'

import moment from 'moment'

const entityStore = useEntityStore()
const portfolioManagerStore = usePortfolioManagerStore()

let root
let stackedBarChart

let seriesToMake = ref([])

const props = defineProps({
    data: {
        type: Array,
        default: () => [],
    },
    loading: {
        type: Boolean,
        default: false,
    },
})

watch(
    () => props.data,
    (newValue) => {
        initializeChart()
    }
)

const transformedData = computed(() => {
    const uniqueProducts = new Set()
    const dateProductMap = new Map()
    const fieldNameMapping = new Map()

    // collect all unique products and organize by date
    props.data.forEach((entry) => {
        const dateKey = moment(entry.date).format('MMMM YYYY')
        if (!dateProductMap.has(dateKey)) {
            dateProductMap.set(dateKey, new Map())
        }

        entry.productFees.forEach((fee) => {
            uniqueProducts.add(fee.product)
            const fieldName = fee.product.replace(/\s+/g, '').toLowerCase()
            fieldNameMapping.set(fieldName, fee.product) // Store the mapping
            dateProductMap.get(dateKey).set(fee.product, fee.upcomingFeeAmount)
        })
    })

    // Chart readable mapped Data
    seriesToMake.value = Array.from(fieldNameMapping.entries()).map(([field, display]) => ({
        field,
        displayName: display,
    }))

    return props.data.map((entry) => {
        const dateKey = moment(entry.date).format('MMMM YYYY')
        const result = { date: dateKey }

        uniqueProducts.forEach((product) => {
            const fieldName = product.replace(/\s+/g, '').toLowerCase()
            result[fieldName] = dateProductMap.get(dateKey).get(product) || 0
        })

        return result
    })
})

const chartHeight = computed(() => {
    const productCount = seriesToMake.value.length
    const baseHeight = 500
    const heightPerProduct = 15

    return Math.max(baseHeight, baseHeight + productCount * heightPerProduct)
})

const chartHeightStyle = computed(() => {
    return {
        height: `${chartHeight.value}px`,
    }
})

const initializeChart = async () => {
    if (root) {
        root.dispose()
    }
    await nextTick()

    am5.addLicense(config.license.AMChartsLicense)
    root = am5.Root.new('feesStackedBarChart')

    stackedBarChart = useStackedBarChart(root)

    if (transformedData.value.length === 0) return

    stackedBarChart.configureStackedBar()
    stackedBarChart.generateAxes(transformedData.value)

    stackedBarChart.generateSeries(transformedData.value, seriesToMake.value)
}
</script>

<style lang="scss" scoped>
.fees-by-product {
    width: 100%;
    position: relative;

    #feesStackedBarChart {
        height: 100%;
        height: 800px;

        div {
            height: 100%;
        }
    }
}
</style>
