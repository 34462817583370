import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { useEntityStore } from '@/stores'

export function useHeatMapChart(root) {
    let chart
    let yAxis
    let xAxis
    let heatLegend
    let clickCallback = null

    const setClickCallback = (callback) => {
        clickCallback = callback
    }

    const configureHeatmap = () => {
        root.setThemes([am5themes_Animated.new(root)])

        chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: 'none',
                wheelY: 'none',
                paddingLeft: 0,
                paddingBottom: 50,
                layout: root.verticalLayout,
            })
        )

        heatLegend = chart.bottomAxesContainer.children.push(
            am5.HeatLegend.new(root, {
                orientation: 'horizontal',
                startColor: am5.color('#1F589A'),
                endColor: am5.color('#E9EDF5'),
                stepCount: 5,
                startText: 'Low Fees',
                endText: 'High Fees',
                showValue: false,
            })
        )

        let legendContainer = chart.bottomAxesContainer.children.push(
            am5.Container.new(root, {
                width: am5.p100,
                height: am5.p100,
                layout: root.horizontalLayout,
                centerY: am5.p50,
                y: am5.p100,
                dy: 20,
                paddingTop: 20,
            })
        )

        // Add right label (High Fees)
        legendContainer.children.push(
            am5.Label.new(root, {
                text: 'High Fees',
                fontSize: 12,
                fontWeight: 700,
                x: 0,
                y: 6,
                centerY: am5.p50,
                paddingRight: 20,
                paddingTop: 30,
            })
        )

        // Add the heat legend to the center
        legendContainer.children.push(heatLegend)

        // Add left label (Low Fees)
        legendContainer.children.push(
            am5.Label.new(root, {
                text: 'Low Fees',
                fontSize: 12,
                fontWeight: 700,
                x: am5.percent(94),
                y: 6,
                centerY: am5.p50,
                paddingLeft: 10,
                paddingTop: 30,
            })
        )

        // Remove the built-in labels from the heat legend
        if (heatLegend.startLabel) {
            heatLegend.startLabel.set('forceHidden', true)
        }
        if (heatLegend.endLabel) {
            heatLegend.endLabel.set('forceHidden', true)
        }
    }

    const generateAxes = (data) => {
        let yRenderer = am5xy.AxisRendererY.new(root, {
            visible: false,
            minGridDistance: 20,
            inversed: true,
            minorGridEnabled: true,
        })

        yRenderer.grid.template.set('visible', false)

        yAxis = chart.yAxes.push(
            am5xy.CategoryAxis.new(root, {
                maxDeviation: 0,
                renderer: yRenderer,
                categoryField: 'product',
            })
        )

        let xRenderer = am5xy.AxisRendererX.new(root, {
            visible: false,
            minGridDistance: 30,
            opposite: true,
            minorGridEnabled: true,
        })

        xRenderer.grid.template.set('visible', false)

        xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                renderer: xRenderer,
                categoryField: 'breadth',
            })
        )
    }

    const generateSeries = (data) => {
        let series = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                calculateAggregates: true,
                stroke: am5.color(0xffffff),
                clustered: false,
                xAxis: xAxis,
                yAxis: yAxis,
                categoryXField: 'breadth',
                categoryYField: 'product',
                valueField: 'value',
            })
        )

        // Actions
        let columnTemplate = series.columns.template

        // Hover state
        columnTemplate.set('cursorOverStyle', 'pointer')
        columnTemplate.states.create('hover', {
            strokeWidth: 3,
            strokeOpacity: 1,
            stroke: am5.color(0x000000),
        })

        // click event
        columnTemplate.events.on('click', function (ev) {
            const dataItem = ev.target.dataItem
            if (dataItem && clickCallback) {
                const cellData = {
                    product: dataItem.dataContext.product,
                    breadth: dataItem.dataContext.breadth,
                    value: dataItem.dataContext.value,
                }
                clickCallback(cellData)
            }
        })

        series.bullets.push(function () {
            let labelBullet = am5.Bullet.new(root, {
                locationX: 0.5,
                locationY: 0.5,
                sprite: am5.Label.new(root, {
                    fill: am5.color(0xffffff),
                    centerX: am5.p50,
                    centerY: am5.p50,
                    populateText: true,
                    fontSize: 12,
                    fontWeight: 'bold',
                }),
            })

            labelBullet.get('sprite').adapters.add('text', function (text, target) {
                let dataItem = target.dataItem
                if (dataItem && dataItem.dataContext) {
                    let value = dataItem.dataContext.value

                    // Format
                    if (value >= 1000000) {
                        return '$' + (value / 1000000).toFixed(1) + 'M'
                    } else if (value >= 1000) {
                        return '$' + (value / 1000).toFixed(0) + 'k'
                    } else {
                        return '$' + value
                    }
                }
                return text
            })

            // Add adapter for the label color
            labelBullet.get('sprite').adapters.add('fill', function (fill, target) {
                let dataItem = target.dataItem
                if (dataItem && dataItem.dataContext) {
                    let value = dataItem.dataContext.value

                    // Calculate relative position in the range
                    let min = series.getPrivate('valueLow', 0)
                    let max = series.getPrivate('valueHigh', 1)
                    let position = (value - min) / (max - min)

                    // Use white text for darker cells, black text for lighter cells
                    return position > 0.5 ? am5.color(0xffffff) : am5.color(0x000000)
                }
                return fill
            })

            return labelBullet
        })

        series.columns.template.events.on('pointerover', function (event) {
            let di = event.target.dataItem
            if (di) {
                heatLegend.showValue(di.get('value', 0))
            }
        })

        series.events.on('datavalidated', function () {
            heatLegend.set('startValue', series.getPrivate('valueHigh'))
            heatLegend.set('endValue', series.getPrivate('valueLow'))
        })

        series.set('heatRules', [
            {
                target: series.columns.template,
                min: am5.color('#E9EDF5'),
                max: am5.color('#1F589A'),
                dataField: 'value',
                key: 'fill',
            },
        ])

        series.data.setAll(data)

        populateAxes(data)
        chart.appear(1000, 100)
    }

    const populateAxes = (data) => {
        let products = []
        let claims = []

        am5.array.each(data, function (row) {
            if (products.indexOf(row.product) == -1) {
                products.push(row.product)
            }
            if (claims.indexOf(row.breadth) == -1) {
                claims.push(row.breadth)
            }
        })

        yAxis.data.setAll(
            products.map(function (item) {
                return { product: item }
            })
        )

        xAxis.data.setAll(
            claims.map(function (item) {
                return { breadth: item }
            })
        )
    }

    const disposeHeatMap = () => {
        if (chart) chart.dispose()
        if (yAxis) yAxis.dispose()
        if (xAxis) xAxis.dispose()
    }

    return {
        configureHeatmap,
        generateAxes,
        generateSeries,
        disposeHeatMap,
        setClickCallback,
    }
}
