<template>
    <div class="fee-summary w-full d-flex mt-2">
        <AonCol v-for="item in feeSummaryData" class="aon-col-4 pl-0">
            <AonCard :title="item?.title">
                <div class="card-content">
                    <h5 :class="`${item?.color}--text`">
                        {{ item?.color === 'error' ? '-' : '' }}${{ item?.value.toLocaleString() }}
                    </h5>
                    <p class="bold small mt-2">{{ item?.scope }}</p>
                </div>
            </AonCard>
        </AonCol>
    </div>
</template>

<script setup>
import { computed, inject, ref, watchEffect } from 'vue'
import { usePortfolioManagerStore } from '@/stores'

const portfolioManagerStore = usePortfolioManagerStore()

const filters = inject('filters')

const props = defineProps({
    data: {
        type: Array,
        default: () => [],
    },
})

const feeSummaryData = computed(() => {
    if (!props.data) return []
    return [
        {
            title: 'Upcoming Fees',
            value: props.data[0] ? props.data[0] : 0,
            color: 'error',
            scope: `Due from ${filters.toUTCString(portfolioManagerStore.dateRange[0])} to ${filters.toUTCString(portfolioManagerStore.dateRange[1])}`,
        },
        {
            title: 'Total Fees Pruned',
            value: props.data[1] ? props.data[1] : 0,
            color: 'success',
            scope: `Within ${filters.toUTCString(portfolioManagerStore.dateRange[0])} to ${filters.toUTCString(portfolioManagerStore.dateRange[1])}`,
        },
        {
            title: 'Lifetime Savings',
            value: props.data[2] ? props.data[2] : 0,
            color: 'grey01',
        },
    ]
})
</script>

<style lang="scss" scoped>
.fee-summary {
    width: 100%;
}
</style>
