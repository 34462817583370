import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { useEntityStore } from '@/stores'

import {
    dataPlum,
    dataTeal,
    dataBlue,
    dataLime,
    dataAqua,
    dataCobalt,
    dataMarine,
    dataMagenta,
    dataRaspberry,
    dataOrange,
    dataYellow,
    dataGreen,
} from '@/styles/js_variables.module.scss'

export function useStackedBarChart(root) {
    let chart
    let scrollableContainer
    let yAxis
    let xAxis
    let legend

    const chartColors = [
        dataPlum,
        dataTeal,
        dataLime,
        dataRaspberry,
        dataOrange,
        dataYellow,
        dataMagenta,
        dataGreen,
        dataBlue,
        dataMarine,
    ]

    const configureStackedBar = () => {
        root.setThemes([am5themes_Animated.new(root)])

        chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: 'none',
                wheelY: 'none',
                paddingLeft: 0,
                paddingRight: 120,
                layout: root.horizontalLayout,
            })
        )

        legend = chart.children.push(
            am5.Legend.new(root, {
                width: 300,
                paddingLeft: 15,
                height: am5.percent(100),
                layout: root.verticalLayout,
            })
        )

        legend.labels.template.setAll({
            maxWidth: 250,
            oversizedBehavior: 'wrap',
            textAlign: 'left',
        })
    }

    const generateAxes = (data) => {
        let xRenderer = am5xy.AxisRendererX.new(root, {
            minorGridEnabled: true,
        })

        xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                categoryField: 'date',
                renderer: xRenderer,
                tooltip: am5.Tooltip.new(root, {}),
            })
        )

        xRenderer.grid.template.setAll({
            location: 1,
        })

        xAxis.data.setAll(data)

        yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                min: 0,
                renderer: am5xy.AxisRendererY.new(root, {
                    strokeOpacity: 0.1,
                }),
                numberFormat: '$#,###',
            })
        )
    }

    const generateSeries = (data, seriesConfig) => {
        const fields = Object.keys(data[0]).filter((key) => key !== 'date')
        fields.forEach((field, index) => {
            const config = seriesConfig.find((s) => s.field === field)
            const series = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    name: config?.displayName || field, // Use the display name if available
                    stacked: true,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: field,
                    categoryXField: 'date',
                    fill: getColor(index), // Add this line to set the color
                })
            )

            series.columns.template.setAll({
                tooltipText: '{categoryX}, {valueY}',
                tooltipY: am5.percent(10),
                stroke: null,
                minHeight: 0,
                hideZero: true,
                visible: '{valueY} > 0',
            })

            series.data.setAll(data)
            series.appear()

            legend.data.push(series)
        })
    }

    const disposeBar = () => {
        if (chart) chart.dispose()
        // if (scrollableContainer) scrollableContainer.dispose()
        if (yAxis) yAxis.dispose()
        if (xAxis) xAxis.dispose()
    }

    const getColor = (index) => {
        if (index < chartColors.length) {
            return chartColors[index]
        }
        // Generate random color if we run out of predefined colors
        return '#' + Math.floor(Math.random() * 16777215).toString(16)
    }

    return {
        configureStackedBar,
        generateAxes,
        generateSeries,
        disposeBar,
    }
}
