<template>
    <div class="company-side-nav d-flex flex-column knights-cloak-active justify-content-between">
        <div class="top d-flex flex-column">
            <div class="company-info py-4 pl-4 pr-8 knights-cloak-80">
                <p class="large bold white--text">{{ entityStore.entity?.name }}</p>
                <div class="ticker-exchange d-flex align-items-center mt-2">
                    <div v-if="entityStore.entity?.stock_ticker" class="ticker grey02 pa-1 mr-2">
                        <p class="extra-small grey05--text">
                            {{ entityStore.entity?.stock_ticker }}
                        </p>
                    </div>
                    <div
                        v-if="entityStore.entity?.stock_ticker"
                        class="country-flag ml-2 mr-1"
                        :class="countryFlag"
                    ></div>
                    <p
                        v-if="entityStore.entity?.stock_exchange"
                        class="extra-small grey05--text"
                        style="padding-top: 1px"
                    >
                        {{ entityStore.entity?.stock_exchange }}
                    </p>
                </div>
                <font-awesome-icon
                    :icon="`fas fa-sitemap`"
                    class="white--text knights-cloak heirarchy-icon"
                    @click="showCompanyHierarchy = true"
                />
                <font-awesome-icon
                    v-if="entityStore.entity?.ultimate_aon_entity_name !== entityStore.entity?.name"
                    v-tooltip="{
                        content: `Ultimate Parent: ${entityStore.entity?.ultimate_aon_entity_name}`,
                        delay: { show: 500, hide: 100 },
                    }"
                    :icon="`fas fa-buildings`"
                    class="white--text knights-cloak buildings-icon"
                    style="cursor: auto"
                />
                <AddToWatchlistButton
                    class="mt-3 pt-4 watchlist-button"
                    :key="entityStore.entity?.aon_entity_pk"
                    :id-values="[entityStore.entity?.aon_entity_pk]"
                    id-type="Entity"
                    type="transparent"
                />
            </div>
            <div class="company-tabs knights-cloak-active">
                <AonTabs
                    v-if="tabData.length > 0"
                    class="details"
                    :key="tabUpdater"
                    :tabs="tabData"
                    direction="vert"
                    icon-type="fas"
                    :current-tab="entityStore.getCurrentCompanyTab"
                    @tab-change="tabChanged"
                />
            </div>
        </div>
        <div class="bottom tavernAle20">
            <div class="pro-tabs tavernAle20">
                <div
                    v-if="isProUser"
                    class="action d-flex align-items-center justify-content-between px-4 py-3"
                    @click="gotoPatentList()"
                >
                    <div class="main d-flex align-items-center">
                        <font-awesome-icon :icon="`fas fa-square-list`" class="white--text mr-3" />
                        <p class="large bold white--text">Patent List</p>
                    </div>
                    <font-awesome-icon :icon="`fas fa-crown`" class="gold--text" />
                </div>
                <div
                    v-if="allowPruningToBeVisible"
                    class="action d-flex align-items-center justify-content-between px-4 py-3"
                    @click="gotoPortfolioManager()"
                >
                    <div class="main d-flex align-items-center">
                        <font-awesome-icon :icon="`fas fa-gear`" class="white--text mr-3" />
                        <p class="large bold white--text">Portfolio Manager</p>
                    </div>
                    <font-awesome-icon :icon="`fas fa-crown`" class="gold--text" />
                </div>
            </div>
            <div class="actions knights-cloak-40">
                <div
                    class="action d-flex align-items-center px-4 py-3"
                    @click="gotoReportGenerator()"
                >
                    <font-awesome-icon
                        :icon="`fas fa-diagram-successor`"
                        class="white--text mr-3"
                    />
                    <p class="large bold white--text">Generate Report</p>
                </div>
            </div>
        </div>
    </div>
    <CompanyHierarchy
        v-if="showCompanyHierarchy"
        :parent-entity-id="parentEntityId"
        :selected-entity-id="parseInt(entityStore.entity?.aon_entity_pk)"
        @cancel="showCompanyHierarchy = false"
    />
</template>

<script setup>
import { ref, computed, inject, onMounted } from 'vue'
import { useAuth } from '@/auth/authPlugin'

import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useEntityStore, useMoat2ProductStore, useReportGenerationStore } from '@/stores'
import { useFlag } from '@unleash/proxy-client-vue'

import CompanyHierarchy from '@/components/company/CompanyHierarchy'
import AddToWatchlistButton from '../Watchlists/WatchlistActions/AddToWatchlistButton'

const router = useRouter()
const { t } = useI18n()
const { user, isAuthenticated, logout } = useAuth()

const entityStore = useEntityStore()
const moat2ProductStore = useMoat2ProductStore()
const reportGenerationStore = useReportGenerationStore()

// flags
const showInnovationTab = useFlag('alpha.innovationTab')
const isProUser = useFlag('ipAlpha.proUsers')
const pruningFeatureFlag = useFlag('ipAlpha.pruningFeature')
const allowAllFeatures = useFlag('ipAlpha.godAccess')

const showCompanyHierarchy = ref(false)
const tabUpdater = ref(0)
const generateLoading = ref(false)

const parentEntityId = computed(() => {
    return entityStore.entity.ultimate_aon_entity_pk
        ? parseInt(entityStore.entity.ultimate_aon_entity_pk)
        : parseInt(entityStore.entity.aon_entity_pk)
})

const countryFlag = computed(() => {
    if (!entityStore.entity.stock_ticker || !entityStore.entity.stock_region) {
        return ''
    }
    return `flag:${entityStore.entity.stock_region}`
})

const allowPruningToBeVisible = computed(() => {
    return (
        (pruningFeatureFlag.value &&
            isProUser.value &&
            user.value.entity_pk === entityStore.entity?.aon_entity_pk) ||
        allowAllFeatures.value
    )
})

// ALL ID'S WILL NEED TO BE CHANGED WHEN UX 2.0 IS COMPLETE - BB
const tabData = computed(() => {
    let finalTabList = []
    finalTabList.push(
        {
            text: 'Insights',
            value: 'profile',
            id: 1,
            tabIcon: 'fa-circle-info',
            iconClass: 'white',
        },
        {
            text: 'Strategy',
            value: 'strategy',
            id: 8,
            tabIcon: 'fa-conveyor-belt-boxes',
            iconClass: 'white',
        },
        {
            text: 'Moat2Product',
            value: 'moat2Product',
            id: 3,
            tabIcon: 'fa-microchip',
            iconClass: 'white',
        },
        {
            text: 'Risk',
            value: 'risk',
            id: 4,
            tabIcon: 'fa-triangle-exclamation',
            iconClass: 'white',
        },
        {
            text: 'Value',
            value: 'value',
            id: 6,
            tabIcon: 'fa-money-bill-wave',
            iconClass: 'white',
        },
        {
            text: 'IP Basics',
            value: 'ipBasics',
            id: 2,
            tabIcon: 'fa-paperclip',
            iconClass: 'white',
        }
    )

    if (showInnovationTab.value) {
        finalTabList.push({
            text: 'Innovation',
            value: 'innovation',
            id: 7,
            tabIcon: 'fa-atom-simple',
            iconType: 'fas',
            iconClass: 'white',
        })
    }
    return finalTabList
})

const tabChanged = (tab) => {
    router.push({
        name: 'Research Company',
        params: { targetPk: entityStore.entity.aon_entity_pk },
        query: { tabName: tab.value },
    })
}

const gotoReportGenerator = async () => {
    generateLoading.value = true

    reportGenerationStore.targetCompany = entityStore.entity.name
    router.push({ name: 'Create Report', query: { source: 'pa' } })
}

const gotoPatentList = () => {
    router.push({
        name: 'Research Company',
        params: { targetPk: entityStore.entity.aon_entity_pk },
        query: { tabName: 'patentList' },
    })
}

const gotoPortfolioManager = () => {
    router.push({
        name: 'Research Company',
        params: { targetPk: entityStore.entity.aon_entity_pk },
        query: { tabName: 'portfolioManager' },
    })
}
</script>

<style lang="scss" scoped>
.company-side-nav {
    min-width: 300px;
    height: calc(100vh);
    position: relative;
    position: sticky;
    top: 0;

    .bottom {
        position: sticky;
        bottom: 0;
        left: 0;
        width: 100%;
        border-top: 1px solid vars.$grey03;

        .action {
            cursor: pointer;

            transition: all 0.3s ease-in-out;

            &:hover {
                background: vars.$grey02;
            }
        }

        .pro-tabs {
            border-bottom: 1px solid vars.$grey03;

            .action {
                border-bottom: solid 1px vars.$grey03;
                &:hover {
                    background: vars.$tavernAle60;
                }

                &:last-of-type {
                    border-bottom: none;
                }
            }
        }
    }

    .heirarchy-icon {
        cursor: pointer;
        background: transparent;
        padding: 8px;
        border-radius: 50%;

        position: absolute;
        top: 14px;
        right: 10px;

        transition: all 0.3s ease-in-out;

        &:hover {
            background: vars.$grey02;
        }
    }

    .buildings-icon {
        cursor: pointer;
        background: transparent;
        padding: 8px;
        border-radius: 50%;

        position: absolute;
        top: 14px;
        right: 10px;

        transition: all 0.3s ease-in-out;
    }

    .company-info {
        border-bottom: 1px solid vars.$grey03;
    }

    .buildings-icon {
        right: 50px;
    }

    .watchlist-button {
        border-top: 1px solid vars.$grey02;
    }

    .ticker {
        border-radius: 4px;
    }

    .country-flag {
        width: 15px;
        height: 15px;
        border-radius: 50%;

        position: relative;

        &::before {
            content: '';
            width: 1px;
            height: 20px;
            background: vars.$grey05;

            position: absolute;
            left: -8px;
            top: -2px;
        }
    }

    :deep(.a-tabs) {
        .tab {
            padding: 22px 16px !important;

            &:hover {
                background: vars.$grey02;
            }
        }
        p {
            font-size: 18px !important;
        }
        &.vert {
            margin-top: 0;
            .selected {
                border-left: 4px solid vars.$gold !important;
                border-bottom: none !important;

                .icon {
                    color: vars.$white !important;
                }
            }
            .icon {
                color: vars.$grey04 !important;
                width: 18px !important;
                height: 18px !important;
                margin-right: 12px !important;
            }
        }
    }
}
</style>
