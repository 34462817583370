<template>
    <div class="fees-by-product-claim w-full d-flex">
        <AonCol class="aon-col-12 px-0">
            <AonCard class="chart-holder" title="Future Fees By Product Claim">
                <div class="card-content">
                    <div id="feesByClaimHeatMap"></div>
                </div>
            </AonCard>
            <AonCoverLoading
                :loading="props.data.length === 0 && !props.loading"
                :show-spinner="false"
                :title="`No Fees By Claim`"
                :message="`No fees were found for ${entityStore.entity?.name} between ${portfolioManagerStore.dateRange[0]} and ${portfolioManagerStore.dateRange[1]}`"
            />
        </AonCol>
    </div>
</template>

<script setup>
import { watch, inject, computed, nextTick, ref } from 'vue'
import { useEntityStore, usePortfolioManagerStore } from '@/stores'

import * as am5 from '@amcharts/amcharts5'
import { useHeatMapChart } from '@/composables/charts/heatMapChart'
import { config } from '@/config'

import moment from 'moment'

const entityStore = useEntityStore()
const portfolioManagerStore = usePortfolioManagerStore()

let root
let heatMapChart

const props = defineProps({
    data: {
        type: Array,
        default: () => [],
    },
    loading: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits(['cell-clicked'])

watch(
    () => props.data,
    (newValue) => {
        initializeChart()
    }
)

const transformedData = computed(() => {
    if (!props.data || props.data.length === 0) return []

    const result = []

    const breadthDisplayMap = {
        narrow: 'Narrow',
        average: 'Average',
        broad: 'Broad',
        veryBroad: 'Very Broad',
    }

    // For each product in the data
    props.data.forEach((item) => {
        const product = item.product
        const feesByClaimBreadth = item.feesByClaimBreadth

        // For each breadth category in the product
        Object.keys(feesByClaimBreadth).forEach((breadthKey) => {
            if (breadthKey !== 'notScored') {
                result.push({
                    product: product,
                    breadth: breadthDisplayMap[breadthKey],
                    value: feesByClaimBreadth[breadthKey],
                })
            }
        })
    })

    return result
})

const initializeChart = async () => {
    if (root) {
        root.dispose()
    }
    await nextTick()

    am5.addLicense(config.license.AMChartsLicense)
    root = am5.Root.new('feesByClaimHeatMap')

    heatMapChart = useHeatMapChart(root)

    heatMapChart.setClickCallback(handleCellClick)

    if (transformedData.value.length === 0) return

    heatMapChart.configureHeatmap()
    heatMapChart.generateAxes(transformedData.value)
    heatMapChart.generateSeries(transformedData.value)
}

const handleCellClick = (cellData) => {
    emit('cell-clicked', cellData)
}
</script>

<style lang="scss" scoped>
.fees-by-product-claim {
    width: 100%;
    position: relative;

    #feesByClaimHeatMap {
        height: 100%;
        height: 600px;

        div {
            height: 100%;
        }
    }
}
</style>
