import {
    ref,
    onMounted,
    onBeforeUnmount,
    onActivated,
    onDeactivated,
    computed,
    nextTick,
} from 'vue'
import { useEntityStore } from '@/stores'
import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { config } from '@/config'
import { cloneDeep } from 'lodash-es'

// chart types
import { useTreemapChart } from './charts/treemapChart'
import { useRangeBulletChart } from './charts/rangeBulletChart'
import { useBarChart } from './charts/barChart'
import { useViolinChart } from './charts/violinChart'

export function focusChart(props) {
    let root
    let treemapChart
    let rangeBulletChart
    let barChart
    let violinChart

    const entityStore = useEntityStore()

    const mappedData = ref([])

    onMounted(() => {
        initializeChart()
    })

    onActivated(() => {
        initializeChart()
    })

    onDeactivated(() => {
        // if (props.chartType === 'violin') {
        //     console.log('onDeactivated')
        //     violinChart?.disposeViolin()
        // }
        if (props.chartType === 'treemap') {
            treemapChart.disposeTreemap()
        }
        if (props.chartType === 'range-bullet') {
            rangeBulletChart.disposeRangeBullet()
        }
        if (props.chartType === 'bar') {
            barChart.disposeBar()
        }
        if (root && root.dom.id) {
            root.dispose()
        }
    })

    onBeforeUnmount(() => {
        // if (props.chartType === 'violin' && violinChart) {
        //     violinChart.disposeViolin()
        // }
        if (props.chartType === 'treemap' && treemapChart) {
            treemapChart.disposeTreemap()
        }
        if (props.chartType === 'range-bullet' && rangeBulletChart) {
            rangeBulletChart.disposeRangeBullet()
        }
        if (props.chartType === 'bar' && barChart) {
            barChart.disposeBar()
        }
        if (root?.dom?.id) {
            root.dispose()
        }
    })

    const initializeChart = async () => {
        await nextTick()
        am5.addLicense(config.license.AMChartsLicense)

        const targetId = `chartHook_${props.chartType}_${entityStore.strategyTabNodeFilter}`
        const existingRoot = am5.registry.rootElements.find((root) => root.dom.id === targetId)

        if (existingRoot && props.chartOverwriteData.length === 0) {
            existingRoot.dispose()
        }

        root = am5.Root.new(`chartHook_${props.chartType}_${entityStore.strategyTabNodeFilter}`)

        treemapChart = props.chartType === 'treemap' ? useTreemapChart(root) : null
        rangeBulletChart = props.chartType === 'range-bullet' ? useRangeBulletChart(root) : null
        barChart = props.chartType === 'bar' ? useBarChart(root) : null

        mapDataForChart(targetDataSource.value, true)
    }

    const targetDataSource = computed(() => {
        let thing = cloneDeep(
            props.chartOverwriteData.length > 0 ? props.chartOverwriteData : props.chartData
        )
        return thing
    })

    const mapDataForChart = (data, overwriteData = false) => {
        if (props.chartType === 'violin') {
            let mapper = []
            let seriesLoopData = []
            const uniqueDisplayNames = new Set()

            const latestYear = Math.max(...data.map((yearGroup) => yearGroup.year))

            data.forEach((yearGroup) => {
                yearGroup.productTechs.forEach((tech) => {
                    uniqueDisplayNames.add(tech.displayName)
                    mapper.push({
                        displayName: tech.displayName,
                        filingYear: yearGroup.year,
                        count: tech.patentCount,
                        powerScore: tech.yoyPatentCount,
                    })
                })
            })

            const latestYearCounts = new Map()
            mapper.forEach((item) => {
                if (item.filingYear === latestYear) {
                    latestYearCounts.set(item.displayName, item.count)
                }
            })

            seriesLoopData = Array.from(uniqueDisplayNames).map((name) => ({
                displayName: name,
                color: '#1F589A',
            }))

            seriesLoopData.sort((a, b) => {
                const countA = latestYearCounts.get(a.displayName) || 0
                const countB = latestYearCounts.get(b.displayName) || 0
                return countB - countA // Descending order
            })

            mappedData.value = mapper
            if (props.chartOverwriteData.length > 0) {
                violinChart.disposeViolin()
            }
            violinChart =
                props.chartType === 'violin'
                    ? useViolinChart(root, seriesLoopData, mappedData.value)
                    : null
        }
        if (props.chartType === 'treemap') {
            let products = new Map()

            data.forEach((item) => {
                const hierarchyLevels = item.hierarchy.split('>')
                const productName = hierarchyLevels[1].trim()
                const techAreaName = hierarchyLevels[2]?.trim()

                if (!products.has(productName)) {
                    products.set(productName, {
                        name: productName,
                        children: [],
                    })
                }

                if (techAreaName) {
                    products.get(productName).children.push({
                        name: techAreaName,
                        value: item.patentCount,
                        heat: item.tpe || 0,
                    })
                }
            })

            mappedData.value = {
                name: '',
                children: Array.from(products.values()).filter(
                    (product) => product.children.length > 0
                ),
            }
        }
        if (props.chartType === 'range-bullet') {
            let mapper = []
            const usedNames = new Set()

            data.forEach((item) => {
                let name = item.displayName
                while (usedNames.has(name)) {
                    name = name + ' '
                }
                usedNames.add(name)

                let mappedItem = {
                    name: name,
                    entry: new Date(item.minFilingDt).getTime(),
                    latest: new Date(item.maxFilingDt).getTime(),
                    p25: new Date(item.p25FilingDt).getTime(),
                    p50: new Date(item.p50FilingDt).getTime(),
                    p75: new Date(item.p75FilingDt).getTime(),
                    peerP50: new Date(item.peerP50FilingDt).getTime(),
                    productDisplayName:
                        entityStore.strategyTabNodeFilter !== 'product'
                            ? item.parentDisplayName
                            : '',
                }
                mapper.push(mappedItem)
            })
            mappedData.value = mapper
                .filter((item) => item.entry !== -62135596800000)
                .sort((a, b) => a.p75 - b.p75)
        }
        if (props.chartType === 'bar') {
            let mapper = []
            const usedNames = new Set()

            data.forEach((item) => {
                let name = item.displayName
                while (usedNames.has(name)) {
                    name = name + ' '
                }
                usedNames.add(name)

                let mappedItem = {
                    name: name,
                    value: item.patentCount,
                    productDisplayName:
                        entityStore.strategyTabNodeFilter !== 'product'
                            ? item.parentDisplayName
                            : '',
                }
                mapper.push(mappedItem)
            })
            mappedData.value = mapper.sort((a, b) => a.value - b.value)
        }

        configureChart()
    }

    const configureChart = () => {
        root.setThemes([am5themes_Animated.new(root)])

        if (props.chartType === 'violin') {
            violinChart.configureViolin({ scrollContainer: true })
            violinChart.createAllSeries({ truncate: false })
        }

        if (props.chartType === 'treemap') {
            treemapChart.removeLabelBullets()
            treemapChart.configureTreemap()
            treemapChart.generateTreemapSeries()
        }

        if (props.chartType === 'range-bullet') {
            rangeBulletChart.disposeRangeBullet()
            rangeBulletChart.configureRangeBullet()
            rangeBulletChart.generateAxes(mappedData.value)
            rangeBulletChart.generateSeries(mappedData.value)
        }

        if (props.chartType === 'bar') {
            barChart.disposeBar()
            barChart.configureBar()
            barChart.generateAxes(mappedData.value)
            barChart.generateSeries(mappedData.value)
        }

        setData()
    }

    const setData = () => {
        if (props.chartType === 'treemap') {
            treemapChart.setTreemapData(mappedData.value)
        }
        if (props.chartType === 'range-bullet') {
            rangeBulletChart.setRangeBulletData(mappedData.value)
        }
        if (props.chartType === 'bar') {
            barChart.setBarData(mappedData.value)
        }
    }

    return {
        mappedData,
        mapDataForChart,
    }
}
