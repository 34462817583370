import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { useEntityStore } from '@/stores'

export function useBarChart(root) {
    let chart
    let scrollableContainer
    let yAxis
    let xAxis
    let barSeries

    const configureBar = () => {
        root.setThemes([am5themes_Animated.new(root)])

        chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: 'none',
                layout: root.verticalLayout,
                wheelable: true,
            })
        )

        scrollableContainer = chart.chartContainer.children.unshift(
            am5.Container.new(root, {
                width: am5.p100,
                height: am5.p100,
                verticalScrollbar: am5.Scrollbar.new(root, {
                    orientation: 'vertical',
                }),
                wheelable: true,
            })
        )

        chart.set(
            'cursor',
            am5xy.XYCursor.new(root, {
                behavior: 'none',
                xAxis: xAxis,
                yAxis: yAxis,
            })
        )
    }

    const generateAxes = (mappedData) => {
        const entityStore = useEntityStore()
        let yRenderer = am5xy.AxisRendererY.new(root, {
            minGridDistance: 30,
            minorGridEnabled: true,
        })

        yRenderer.grid.template.set('location', 1)

        yAxis = chart.yAxes.push(
            am5xy.CategoryAxis.new(root, {
                maxDeviation: 0,
                categoryField: 'name',
                renderer: yRenderer,
                tooltip: am5.Tooltip.new(root, { themeTags: ['axis'] }),
                bullet: function (root, axis, dataItem) {
                    return am5xy.AxisBullet.new(root, {
                        location: 0.5,
                        sprite: am5.Label.new(root, {
                            text: dataItem.dataContext.productDisplayName,
                            fill: am5.color('#82939A'),
                            centerY: am5.p50,
                            centerX: am5.percent(97),
                            dy: -8,
                            fontSize: 12,
                        }),
                    })
                },
            })
        )

        yAxis.get('renderer').labels.template.setAll({
            dy: entityStore.strategyTabNodeFilter === 'product' ? 0 : 9,
        })

        let xRenderer = am5xy.AxisRendererX.new(root, {
            strokeOpacity: 0.1,
            minGridDistance: 80,
        })

        xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
                maxDeviation: 0,
                min: 0,
                numberFormatter: am5.NumberFormatter.new(root, {
                    numberFormat: '#,###a',
                }),
                extraMax: 0.1,
                renderer: xRenderer,
            })
        )

        yAxis.data.setAll(mappedData)
        chart.yAxesAndPlotContainer.set('height', mappedData.length * 60)
        chart.yAxesAndPlotContainer.set('paddingBottom', 10)
        scrollableContainer.children.push(chart.yAxesAndPlotContainer)
    }

    const generateSeries = (mappedData) => {
        barSeries = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                valueXField: 'value',
                categoryYField: 'name',
                tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: 'left',
                    labelText: '{valueX}',
                }),
            })
        )

        barSeries.columns.template.adapters.add('fill', function () {
            return am5.color('#102B47')
        })

        barSeries.columns.template.adapters.add('stroke', function () {
            return am5.color('#102B47')
        })

        barSeries.data.setAll(mappedData)
    }

    const disposeBar = () => {
        if (chart) chart.dispose()
        if (scrollableContainer) scrollableContainer.dispose()
        if (yAxis) yAxis.dispose()
        if (xAxis) xAxis.dispose()
        if (barSeries) barSeries.dispose()
    }

    const setBarData = (mappedData) => {
        barSeries.data.setAll(mappedData)
    }

    return {
        configureBar,
        generateAxes,
        generateSeries,
        setBarData,
        disposeBar,
    }
}
