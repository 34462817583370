<template>
    <AonModal
        class="patent-list"
        :class="[{ inline: props.inline }, { absolute: props.absolute }]"
        message=""
        col-width="10"
        @close="closePatentList"
    >
        <div class="modal-content d-flex flex-column">
            <PatentDetails v-show="patentViewerStore.patentDetailsOpen" />
            <div class="patent-list-header d-flex align-items-center">
                <div class="source-display py-4 px-4">
                    <div class="basics-wrap d-flex align-items-center">
                        <p v-if="showLitigationTitle" class="inter-font semi-bold grey01--text">
                            Litigation for:
                        </p>
                        <p v-if="showCountry" class="inter-font semi-bold grey01--text mr-1">
                            {{ patentViewerStore.ipBasicsObject?.country }} |
                        </p>
                        <p v-if="showBasicsInfo" class="inter-font semi-bold grey01--text">
                            {{ publicationType }}{{ claimBreadthText }}{{ validityText }}
                        </p>
                    </div>
                    <h4 class="inter-font semi-bold grey01--text mt-1 mb-n4">
                        {{ entityTitle }}
                    </h4>
                </div>
            </div>

            <div class="table-holder d-flex flex-column flex-grow-1 h-full pa-4">
                <!-- <div v-if="!props.allowPruning" class="actions-parent d-flex align-items-center justify-content-end w-full mt-n6 mb-4">
                    <AonButton
                        label="Export Current Patent List"
                        :loading="exportLoading"
                        :text-with-icon="true"
                        type="prominent"
                        :disabled="noRows || loading"
                        :icon-options="{ iconStyle: 'fas', iconName: 'fa-file-arrow-down' }"
                        @clicked="exportPatents()"
                    />
                </div> -->
                <div class="actions-parent d-flex align-items-center w-full">
                    <div
                        v-if="props.allowPruning"
                        class="bulk-actions mb-4 d-flex align-items-center justify-content-between"
                    >
                        <div class="left d-flex align-items-center">
                            <div class="total-selected mr-10 ml-1">
                                <p class="geist-font">{{ selectedRows.length }} selected</p>
                            </div>
                            <div
                                class="bulk-action d-flex align-items-center mr-4"
                                @click="bulkPrune"
                            >
                                <font-awesome-icon :icon="`fas fa-seedling`" class="pruned mt-n1" />
                                <p class="geist-font success--text bold ml-2">Prune</p>
                            </div>
                            <div class="bulk-action d-flex align-items-center" @click="bulkUnprune">
                                <font-awesome-icon
                                    :icon="`fas fa-seedling`"
                                    class="grey03--text mt-n1"
                                />
                                <p class="geist-font grey03--text bold ml-2">Unprune</p>
                            </div>
                            <div class="divide"></div>
                            <!-- <div class="bulk-action d-flex align-items-center" @click="bulkUnprune">
                                <font-awesome-icon
                                    :icon="`fas fa-file-arrow-down`"
                                    class="knightsCloak--text mt-n1"
                                />
                                <p class="geist-font knightsCloak--text bold ml-2">Export Current Patent List</p>
                            </div> -->
                        </div>
                    </div>
                    <AonButton
                        class="mb-3"
                        label="Export Current Patent List"
                        :loading="exportLoading"
                        :text-with-icon="true"
                        type="prominent"
                        :disabled="noRows || loading"
                        :icon-options="{ iconStyle: 'fas', iconName: 'fa-file-arrow-down' }"
                        @clicked="exportPatents()"
                        style="transform: scale(0.9); transform-origin: left top"
                    />
                </div>
                <MoatTable
                    class="w-full h-full"
                    :class="themeClass"
                    :column-defs="colDefs"
                    :tooltip-interaction="true"
                    :tooltip-mouse-track="true"
                    :tooltip-show-delay="500"
                    tooltip-show-mode="whenTruncated"
                    :side-bar="sideBar"
                    :autoSizeStrategy="sizingStrategy"
                    row-model-type="serverSide"
                    :cache-block-size="100"
                    :max-blocks-in-cache="50"
                    :get-row-id="getRowId"
                    :row-selection="rowSelection"
                    :noRowsOverlayComponent="MTCustomNoRowsOverlay"
                    :noRowsOverlayComponentParams="noRowsOverlayComponentParams"
                    pagination
                    @grid-ready="gridInit"
                    @cell-clicked="onRowSelected"
                    @cell-key-down="onCellKeyDown"
                    @row-selected="addToBulk"
                />
                <div class="total-holder d-flex align-items-center">
                    <p class="total inter-font knightsCloak--text mr-4 pr-4">
                        <span class="bold">{{ filteredPatents.toLocaleString() }}</span> matches of
                        <span class="bold">{{ totalPatents.toLocaleString() }}</span> patents
                    </p>
                    <p v-if="props.allowPruning" class="inter-font success--text">
                        Total Fees Pruned:
                        <span class="bold">${{ parseInt(totalFeesPruned).toLocaleString() }}</span>
                    </p>
                </div>
            </div>
        </div>
    </AonModal>
</template>

<script setup>
import { ref, inject, onBeforeUnmount, onMounted, computed, watch } from 'vue'
import { usePatentViewerStore, useEntityStore, useMoat2ProductStore } from '@/stores'
import {
    getPatentList,
    getLitPatentList,
    getPatentListIPBasics,
    getPatentListInventor,
} from '@/api/patentViewer.js'
import { prunePatents, unprunePatents } from '@/api/portfolioManager.js'
import { createReport, patchReport } from '@/api/reports'

import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'
import { dateComparator, dateFilterComparator } from '@/components/moatTable/helpers/compare.js'
import { moatParamBuilder } from '@/components/moatTable/helpers/queryBuilder.js'
import MTCustomNoRowsOverlay from '@/components/moatTable/MTCustomNoRowsOverlay.vue'
import MTPagination from '@/components/moatTable/helpers/MTPagination.vue'
import PatentDetails from '@/components/patentViewer/PatentDetails.vue'

import { useI18n } from 'vue-i18n'
import { debounce } from 'lodash-es'
import { useFlag } from '@unleash/proxy-client-vue'
import { useAuth } from '@/auth/authPlugin'
import { sort } from 'd3'

const { user } = useAuth()
const logger = inject('logger')
const filters = inject('filters')
const eventBus = inject('eventBus')
const { t } = useI18n()

const patentViewerStore = usePatentViewerStore()
const entityStore = useEntityStore()
const emit = defineEmits(['close-patent-list'])
const enhancedLitigationData = useFlag('ipAlpha.enhancedLitigationData')
const props = defineProps({
    inline: {
        type: Boolean,
        default: false,
    },
    absolute: {
        type: Boolean,
        default: false,
    },
    entityPk: {
        type: Number,
        default: null,
    },
    entityOnly: {
        type: Boolean,
        default: false,
    },
    allowPruning: {
        type: Boolean,
        default: false,
    },
    passedFilters: {
        type: Object,
        default: () => ({}),
    },
})

const loading = ref(true)
const exportLoading = ref(false)
const gridApi = ref(null)
const themeClass = ref('ag-theme-quartz')
const sizingStrategy = ref({
    type: 'fitProvidedWidth',
    width: 3200,
})
const tableParams = ref({})
const noRows = ref(false)
const tablePageSize = ref(100)
const tablePageNum = ref(0)
const totalPatents = ref(0)
const filteredPatents = ref(0)
const selectedPatentIndex = ref(0)
const sideBar = ref(null)
const previousSortModel = ref([])
const noRowsOverlayComponentParams = ref({
    message: 'There are no patents matching your search criteria.',
})
const selectedRows = ref([])
const totalFeesPruned = ref(0)
const hasSetupTotalPruned = ref(false)

onMounted(() => {
    eventBus.on('next-patent', async () => {
        const nextRow = gridApi.value.getDisplayedRowAtIndex(selectedPatentIndex.value + 1)
        if (nextRow?.data?.patentId) {
            gridApi.value.deselectAll()
            gridApi.value.setFocusedCell(selectedPatentIndex.value + 1, 'patentId')
            gridApi.value.setNodesSelected({ nodes: [nextRow], newValue: true })
            await processRowSelection(nextRow.data.publicationPk, nextRow.data)

            selectedPatentIndex.value++
        }
    })
    eventBus.on('previous-patent', async () => {
        const previousRow = gridApi.value.getDisplayedRowAtIndex(selectedPatentIndex.value - 1)
        if (previousRow?.data?.patentId) {
            gridApi.value.deselectAll()
            gridApi.value.setFocusedCell(selectedPatentIndex.value - 1, 'patentId')
            gridApi.value.setNodesSelected({ nodes: [previousRow], newValue: true })
            await processRowSelection(previousRow.data.publicationPk, previousRow.data)
            selectedPatentIndex.value--
        }
    })

    eventBus.on('close-patent', () => {
        patentViewerStore.targetPatentId = null
    })
})

onBeforeUnmount(() => {
    patentViewerStore.resetState()
    eventBus.off('next-patent')
    eventBus.off('previous-patent')
    eventBus.off('close-patent')
})

const colDefs = computed(() => {
    const columns = []

    if (props.allowPruning) {
        columns.push({
            headerName: '',
            field: 'prune',
            width: 44,
            cellRenderer: (params) => {
                if (params.data) {
                    const iconColorClass = params.data.prune ? 'pruned' : 'not-pruned'
                    return `
                        <div class="pt-1 d-flex justify-content-center pointer prune-icon" style="margin-top: 8px">
                            <svg class="${iconColorClass}" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
                                <path 
                                    d="M5.33333 6.68333V12H6.66667V8.66667C7.37778 8.66667 8.05833 8.53333 8.70833 8.26667C9.35833 8 9.93333 7.61667 10.4333 7.11667C10.9333 6.61667 11.3194 6.03889 11.5917 5.38333C11.8639 4.72778 12 4.04444 12 3.33333V2H10.6667C9.95556 2 9.27222 2.13611 8.61667 2.40833C7.96111 2.68056 7.38333 3.06667 6.88333 3.56667C6.80556 3.64444 6.73333 3.71944 6.66667 3.79167C6.6 3.86389 6.53889 3.93889 6.48333 4.01667C6.36111 3.55 6.18611 3.11111 5.95833 2.7C5.73056 2.28889 5.44444 1.91111 5.1 1.56667C4.6 1.06667 4.02222 0.680556 3.36667 0.408333C2.71111 0.136111 2.03333 0 1.33333 0H0V1.33333C0 2.04444 0.133333 2.72778 0.4 3.38333C0.666667 4.03889 1.05 4.61667 1.55 5.11667C2.05 5.61667 2.62778 6.00278 3.28333 6.275C3.93889 6.54722 4.62222 6.68333 5.33333 6.68333Z"
                                    fill="#000000"
                                />
                            </svg>
                        </div>
                    `
                }
                return ''
            },
            filter: 'agSetColumnFilter',
            filterParams: {
                values: [true, false],
                valueFormatter: (p) => {
                    return p.value === true ? 'Pruned' : 'Not Pruned'
                },
            },
            sortable: false,
            suppressMenu: true,
        })
    }

    columns.push(
        {
            field: 'patentId',
            width: 140,
            cellClass: 'link',
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['clear'],
                defaultOption: 'contains',
                filterOptions: ['startsWith', 'equals', 'contains'],
                maxNumConditions: 1,
            },
            sort: 'desc',
        },
        {
            field: 'title',
            flex: 2,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['clear'],
                defaultOption: 'contains',
                filterOptions: ['startsWith', 'equals', 'contains'],
                maxNumConditions: 1,
            },
        }
    )

    if (props.allowPruning) {
        columns.push(
            {
                headerName: 'Next Fee Due Date',
                field: 'nextFeeDueDate',
                width: 180,
                valueGetter: (p) => {
                    if (p.data && p.data.nextFeeDueDate) {
                        let date = filters.toUTCString(p.data.nextFeeDueDate)
                        if (date === '1/1/1') {
                            date = '--'
                        }
                        return `${date}`
                    }
                    return '--'
                },
                comparator: dateComparator,
                filter: 'agDateColumnFilter',
                filterParams: {
                    suppressAndOrCondition: true,
                    buttons: ['clear'],
                    defaultOption: 'inRange',
                    filterOptions: ['inRange'],
                    maxNumConditions: 1,
                },
            },
            {
                headerName: 'Remaining Fees',
                field: 'remainingFees',
                width: 180,
                valueFormatter: (p) => {
                    if (p.data && p.data.remainingFees !== undefined) {
                        return `$${p.data.remainingFees.toLocaleString()}`
                    }
                    return '--'
                },
                filter: 'agNumberColumnFilter',
                filterParams: {
                    buttons: ['clear'],
                    defaultOption: 'equals',
                    filterOptions: ['equals', 'lessThan', 'greaterThan'],
                    maxNumConditions: 1,
                },
            },
            {
                headerName: 'Products',
                field: 'products',
                width: 150,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: {
                    buttons: ['clear'],
                    defaultOption: 'contains',
                    filterOptions: ['startsWith', 'equals', 'contains'],
                    maxNumConditions: 1,
                },
            }
        )
    }

    columns.push(
        {
            field: 'entities',
            headerName: 'Assignee',
            flex: 2,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['clear'],
                defaultOption: 'contains',
                filterOptions: ['startsWith', 'equals', 'contains'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'ultimateEntities',
            headerName: 'Ultimate Assignee',
            flex: 2,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['clear'],
                defaultOption: 'contains',
                filterOptions: ['startsWith', 'equals', 'contains'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'legalStatus',
            width: 140,
            filter: 'agSetColumnFilter',
            filterParams: {
                values: ['active', 'inactive'],
                valueFormatter: (p) => {
                    if (p.value) {
                        return p.value === 'active' ? 'Active' : 'Inactive'
                    }
                },
            },
        },
        {
            headerName: 'Filing Date',
            valueGetter: (p) => {
                if (p.data) {
                    let date = filters.toUTCString(p.data.filingDate)

                    if (date === '1/1/1') {
                        date = '--'
                    }

                    return `${date}`
                }
            },
            field: 'filingDate',
            comparator: dateComparator,
            width: 140,
            filter: 'agDateColumnFilter',
            filterParams: {
                suppressAndOrCondition: true,
                buttons: ['clear'],
                defaultOption: 'inRange',
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            headerName: 'Publication Date',
            valueGetter: (p) => {
                if (p.data) {
                    let date = filters.toUTCString(p.data.publicationDate)

                    if (date === '1/1/1') {
                        date = '--'
                    }

                    return `${date}`
                }
            },
            field: 'publicationDate',
            comparator: dateComparator,
            width: 140,
            filter: 'agDateColumnFilter',
            filterParams: {
                suppressAndOrCondition: true,
                buttons: ['clear'],
                defaultOption: 'inRange',
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            headerName: 'Priority Date',
            valueGetter: (p) => {
                if (p.data) {
                    let date = filters.toUTCString(p.data.priorityDate)

                    if (date === '1/1/1') {
                        date = '--'
                    }
                    return `${date}`
                }
            },
            field: 'priorityDate',
            comparator: dateComparator,
            width: 140,
            filter: 'agDateColumnFilter',
            filterParams: {
                suppressAndOrCondition: true,
                buttons: ['clear'],
                defaultOption: 'inRange',
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            headerName: 'Est. Expiration Date',
            valueGetter: (p) => {
                if (p.data) {
                    let date = filters.toUTCString(p.data.estimatedExpirationDate)

                    if (date === '1/1/1') {
                        date = '--'
                    }

                    return `${date}`
                }
            },
            field: 'estimatedExpirationDate',
            comparator: dateComparator,
            width: 150,
            filter: 'agDateColumnFilter',
            filterParams: {
                suppressAndOrCondition: true,
                buttons: ['clear'],
                defaultOption: 'inRange',
                filterOptions: ['inRange'],
                maxNumConditions: 1,
            },
        },
        {
            field: 'applicationNumber',
            width: 150,
            filter: 'agNumberColumnFilter',
        },
        {
            field: 'claimBreadth',
            width: 120,
            filter: 'agSetColumnFilter',
            filterParams: {
                values: ['Very Broad', 'Broad', 'Average', 'Narrow', 'Not Scored'],
            },
        },
        {
            field: 'validity',
            width: 110,
            filter: 'agSetColumnFilter',
            filterParams: {
                values: ['Low', 'Medium', 'High', 'Not Ranked'],
            },
        },
        {
            field: 'litigationCount',
            width: 130,
            valueFormatter: (p) => {
                if (p.data) {
                    return p.data.litigationCount.toLocaleString()
                }
            },
            filter: 'agNumberColumnFilter',
        },
        {
            field: 'jurisdiction',
            width: 130,
            filter: 'agTextColumnFilter',
            filterParams: {
                buttons: ['clear'],
                defaultOption: 'contains',
                filterOptions: ['startsWith', 'equals', 'contains'],
                maxNumConditions: 1,
            },
        }
    )

    return columns
})

const rowSelection = computed(() => {
    if (props.allowPruning) {
        return {
            mode: 'multiRow',
            checkboxes: true,
            suppressRowDeselection: false,
            headerCheckbox: false,
        }
    } else {
        return {
            mode: 'single',
            checkboxes: false,
            enableClickSelection: true,
            suppressRowDeselection: false,
        }
    }
})

const showLitigationTitle = computed(() => patentViewerStore.listViewType === 'lit')

const showCountry = computed(() => patentViewerStore.ipBasicsObject?.country)

const showBasicsInfo = computed(() => patentViewerStore.ipBasicsObject)

const publicationType = computed(() => {
    const type = patentViewerStore.ipBasicsObject?.publicationType
    return type === 'A' ? 'Applications' : type === 'G' ? 'Grants' : 'Total'
})

const claimBreadthText = computed(() => {
    const breadth = patentViewerStore.ipBasicsObject?.claimBreadth
    return breadth ? ` | ${breadth}` : ''
})

const validityText = computed(() => {
    const validity = patentViewerStore.ipBasicsObject?.validity
    return validity ? ` - ${validity}` : ''
})

const entityTitle = computed(() => {
    const tech = patentViewerStore.technologyName
    return `${patentViewerStore.entityName}${tech ? ` - ${tech}` : ''}`
})

watch(
    () => props.passedFilters,
    (newVal, oldVal) => {
        if (newVal !== oldVal) {
            applyPassedFilters(newVal)
        }
    }
)

watch(
    () => filteredPatents.value,
    (newValue) => {
        if (gridApi.value) {
            const showResetButton = newValue === 0 && totalPatents.value !== 0
            const updatedMessage = buildNoRowsMessage(showResetButton)
            if (showResetButton) {
                setTimeout(() => {
                    gridApi.value.setGridOption('noRowsOverlayComponentParams', {
                        message: updatedMessage,
                        showResetFilterButton: showResetButton,
                        resetFilters: () => {
                            gridApi.value.setFilterModel(null)
                            gridApi.value.hideOverlay()
                            gridApi.value.refreshServerSide({ purge: true })
                        },
                    })
                    gridApi.value.showNoRowsOverlay()
                }, 100)
            } else {
                gridApi.value.hideOverlay()
            }
        }
    }
)

const gridInit = (params) => {
    gridApi.value = params.api
    setupServerSideData()
    gridApi.value.closeToolPanel()
    sideBar.value = {
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                    suppressRowGroups: true,
                    suppressValues: true,
                    suppressPivotMode: true,
                },
            },
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
            },
        ],
    }
}

const buildNoRowsMessage = (filtersApplied) => {
    const message = patentViewerStore.ipBasicsObject
        ? `There doesn't seem to be any patents for - ${patentViewerStore.entityName} - ${patentViewerStore.ipBasicsObject.country} ${
              patentViewerStore.ipBasicsObject.publicationType === 'A'
                  ? 'Applications'
                  : patentViewerStore.ipBasicsObject.publicationType === 'G'
                    ? 'Grants'
                    : 'Total'
          } ${patentViewerStore.ipBasicsObject.claimBreadth ? ' | ' + '-' + patentViewerStore.ipBasicsObject.claimBreadth : ''} ${patentViewerStore.ipBasicsObject.validity ? patentViewerStore.ipBasicsObject.validity : ''}`
        : `There are no patents matching your search criteria.`

    if (filtersApplied) {
        return message + ', with the current filters applied.'
    }

    return message
}

const getRowId = (params) => {
    return params.data.patentId
}

const setupServerSideData = async () => {
    const datasource = createServerSideDatasource()
    gridApi.value.setGridOption('serverSideDatasource', datasource)
}

const createServerSideDatasource = () => {
    const debouncedGetRows = debounce(async (params) => {
        let response
        if (patentViewerStore.strategyTabPatentListObj) {
            response = await onGetPatentList(
                params.request,
                patentViewerStore.strategyTabPatentListObj
            )
        } else if (patentViewerStore.ipBasicsObject) {
            response = await onGetPatentListIPBasics(params.request, props.entityPk)
        } else if (props.entityOnly) {
            response = await onGetPatentListIPBasics(params.request, props.entityPk)
            patentViewerStore.entityPkList = [props.entityPk]
        } else if (patentViewerStore.inventorPk) {
            response = await onGetPatentListInventor(params.request)
        } else {
            response = await onGetPatentList(params.request)
        }

        if (response && response.success) {
            params.success({ rowData: response.rows })
        } else {
            params.fail()
        }
    }, 1000)

    return {
        getRows: (params) => {
            debouncedGetRows(params)
        },
    }
}

const onGetPatentList = async (paramsFromTable, strategyTabPatentListObj = null) => {
    const fetchFunction = async (tableParams) => {
        let nodeParams = null
        if (strategyTabPatentListObj) {
            nodeParams = strategyTabPatentListObj
        } else {
            nodeParams = {
                nodeIds: patentViewerStore.nodeIdList,
                entityPks: props.entityPk ? [props.entityPk] : patentViewerStore.entityPkList,
            }
        }

        return patentViewerStore.listViewType === 'lit'
            ? await getLitPatentList(patentViewerStore.nodeIdList[0], tableParams)
            : await getPatentList(nodeParams, tableParams)
    }

    return handlePatentListRequest(paramsFromTable, fetchFunction)
}

const onGetPatentListIPBasics = async (paramsFromTable, entityPk = null) => {
    const fetchFunction = async (tableParams) => {
        return await getPatentListIPBasics(
            entityPk ? entityPk : patentViewerStore.ipBasicsEntityPK,
            entityPk ? {} : patentViewerStore.ipBasicsObject,
            tableParams
        )
    }

    return handlePatentListRequest(paramsFromTable, fetchFunction)
}

const onGetPatentListInventor = async (paramsFromTable) => {
    const fetchFunction = async (tableParams) => {
        return await getPatentListInventor(
            {
                inventorPk: patentViewerStore.inventorPk,
                entityPk: patentViewerStore.entityPk,
            },
            tableParams
        )
    }
    return handlePatentListRequest(paramsFromTable, fetchFunction)
}

const handlePatentListRequest = async (paramsFromTable, fetchFunction) => {
    loading.value = true

    const currentSortModel = paramsFromTable.sortModel || []
    const sortChanged = JSON.stringify(previousSortModel.value) !== JSON.stringify(currentSortModel)

    if (sortChanged) {
        tablePageNum.value = 0
        previousSortModel.value = [...currentSortModel]
        gridApi.value.refreshServerSide({ purge: true })
        return
    }

    try {
        tableParams.value = moatParamBuilder(
            paramsFromTable,
            { page_size: tablePageSize.value, last_row_num: 0, page: tablePageNum.value },
            null
        )

        const data = await fetchFunction(tableParams.value)

        if (!totalPatents.value) {
            totalPatents.value = data.data.rowCount
            filteredPatents.value = data.data.rowCount
        } else {
            filteredPatents.value = data.data.rowCount
        }

        if (!hasSetupTotalPruned.value) {
            hasSetupTotalPruned.value = false
            totalFeesPruned.value = data.data.totalFeesPruned
        }

        if (data.data.results.length === 0) {
            gridApi.value.showNoRowsOverlay()
            noRows.value = true
        }
        tablePageNum.value++
        return {
            success: true,
            rows: data.data.results,
        }
    } catch (error) {
        logger.error(error)
        return {
            success: false,
        }
    } finally {
        loading.value = false
    }
}

const processRowSelection = async (patentId, rowData) => {
    if (patentViewerStore.patentExistsInCache(patentId)) {
        await updateTargetPatentId(patentId)
    } else {
        rowData.isRowData = true
        patentViewerStore.targetPatentId = patentId
        debouncedUpdateTargetPatentData(patentId, rowData)
    }
}

const addToBulk = (params) => {
    selectedRows.value = []
    const selectedNodes = gridApi.value.getSelectedNodes()

    if (selectedNodes && selectedNodes.length > 0) {
        selectedNodes.forEach((node) => {
            if (node.data) {
                selectedRows.value.push(node.data)
            }
        })
    }
}

const onRowSelected = async (params) => {
    if (params.column.colId === 'prune') {
        params.data.prune = !params.data.prune

        const rowNode = gridApi.value.getRowNode(params.data.patentId)
        if (rowNode) {
            rowNode.setData({ ...params.data })
        }

        submitPruneStatus(params.data)
    } else {
        selectedPatentIndex.value = params.rowIndex
        if (params?.data?.patentId) {
            await processRowSelection(params.data.publicationPk, params.data)
        }
    }
}

const bulkPrune = async () => {
    selectedRows.value.forEach((row) => {
        row.prune = true
        const rowNode = gridApi.value.getRowNode(row.patentId)
        if (rowNode) {
            rowNode.setData({ ...row })
        }
    })

    await submitPruneStatus(null, 'prune')
}

const bulkUnprune = async () => {
    selectedRows.value.forEach((row) => {
        row.prune = false
        const rowNode = gridApi.value.getRowNode(row.patentId)
        if (rowNode) {
            rowNode.setData({ ...row })
        }
    })

    await submitPruneStatus(null, 'unprune')
}

const updateTargetPatentId = async (patentId) => {
    if (patentId) {
        patentViewerStore.targetPatentId = patentId
        await patentViewerStore.fetchPatentDetails(patentId)
    }
}

const updateTargetPatentData = async (patentId, rowData) => {
    if (patentId) {
        patentViewerStore.setCachedPatentRowDetail(patentId, rowData)
        await patentViewerStore.fetchPatentDetails(patentId)
    }
}

const debouncedUpdateTargetPatentData = debounce(updateTargetPatentData, 500)

const onCellKeyDown = async (params) => {
    selectedPatentIndex.value = params.rowIndex
    let newPatentId = null
    let row = null
    if (params.event.key === 'ArrowUp') {
        row = gridApi.value.getDisplayedRowAtIndex(selectedPatentIndex.value - 1)
        if (row?.data?.patentId) {
            newPatentId = row.data.publicationPk
        }
    } else if (params.event.key === 'ArrowDown') {
        row = gridApi.value.getDisplayedRowAtIndex(selectedPatentIndex.value + 1)
        if (row?.data?.patentId) {
            newPatentId = row.data.publicationPk
        }
    }
    await processRowSelection(newPatentId, row.data)
}

const applyPassedFilters = (filters) => {
    if (!gridApi.value || !filters) return

    const filterModel = {}

    // Handle product filter (partial string match)
    if (filters.product) {
        filterModel.products = {
            filterType: 'text',
            type: 'contains',
            filter: filters.product,
        }
    }

    // Handle breadth filter (exact match)
    if (filters.breadth) {
        filterModel.claimBreadth = {
            filterType: 'set',
            values: [filters.breadth],
        }
    }

    // Apply the filter model to the grid
    gridApi.value.setFilterModel(filterModel)

    // Refresh the data with the new filters
    gridApi.value.refreshServerSide({ purge: true })

    // Reset pagination to first page
    tablePageNum.value = 0
}

const closePatentList = () => {
    patentViewerStore.resetState()
}

const exportPatents = async () => {
    exportLoading.value = true

    let tableFilters = {
        'FC[]': tableParams.value.FC,
        'FT[]': tableParams.value.FT,
        'FV[]': tableParams.value.FV,
    }

    const exportParams = {
        requested_user: user.value.id,
        report_type: 'patent_export',
        requested_application: 'ip_alpha',
        report_name: getReportName(),
        report_input: {
            entityPks: patentViewerStore.entityPkList.map(Number),
            queryFilters: tableFilters,
        },
    }

    if (patentViewerStore.ipBasicsObject) {
        exportParams.report_input.entityPatentRequest = patentViewerStore.ipBasicsObject
    } else if (patentViewerStore.inventorPk) {
        exportParams.report_input.inventor_pk = patentViewerStore.inventorPk
        exportParams.report_input.entity_pk = parseInt(patentViewerStore.entityPk)
    } else {
        exportParams.report_input.node_ids = patentViewerStore.nodeIdList
    }

    try {
        const { data } = await createReport(exportParams)
        submitReportForGeneration(data)
    } catch (err) {
        logger.error(err)
    }
}

const getReportName = () => {
    if (patentViewerStore.ipBasicsObject) {
        return `Patent Export - ${patentViewerStore.entityName} - ${patentViewerStore.ipBasicsObject.country} ${
            patentViewerStore.ipBasicsObject.publicationType === 'A'
                ? 'Applications'
                : patentViewerStore.ipBasicsObject.publicationType === 'G'
                  ? 'Grants'
                  : 'Total'
        }${patentViewerStore.ipBasicsObject.claimBreadth ? ' | ' + patentViewerStore.ipBasicsObject.claimBreadth : ''}${patentViewerStore.ipBasicsObject.validity ? ' - ' + patentViewerStore.ipBasicsObject.validity : ''}`
    } else if (patentViewerStore.inventorName) {
        return `Patent Export - ${patentViewerStore.entityName} - ${patentViewerStore.inventorName}`
    } else {
        let reportName = `Patent Export - ${patentViewerStore.entityName ? patentViewerStore.entityName : entityStore.entity.name}`
        if (patentViewerStore.technologyName && patentViewerStore.technologyName.trim() !== '') {
            reportName += ` - ${patentViewerStore.technologyName}`
        }
        return reportName
    }
}

const submitReportForGeneration = async (reportData) => {
    const params = {
        report_action: 'submit',
    }

    try {
        await patchReport(reportData.report_pk, params)
    } catch (err) {
        logger.error(err)
    } finally {
        exportLoading.value = false
        eventBus.emit('snacktime', {
            type: 'success',
            message:
                'The Export has been successfully submitted for generation. Feel free to make changes and submit another.',
            link: {
                text: 'Export',
                route: '/reports',
            },
            contextual: false,
        })
    }
}

const submitPruneStatus = async (rowData, type = null) => {
    let publicationPks = []
    let feesToUpdate = 0

    if (rowData) {
        publicationPks = [parseInt(rowData.publicationPk)]
        if (!type) {
            type = rowData.prune ? 'prune' : 'unprune'
        }
        feesToUpdate = rowData.remainingFees || 0
    } else {
        publicationPks = selectedRows.value.map((row) => parseInt(row.publicationPk))
        feesToUpdate = selectedRows.value.reduce(
            (total, row) => total + (row.remainingFees || 0),
            0
        )
    }

    try {
        // Execute the appropriate action based on type
        if (type === 'prune') {
            await prunePatents(publicationPks)
            totalFeesPruned.value += feesToUpdate
        } else if (type === 'unprune') {
            await unprunePatents(publicationPks)
            totalFeesPruned.value = Math.max(0, totalFeesPruned.value - feesToUpdate)
        } else {
            logger.error('Invalid prune action type')
            return
        }
    } catch (err) {
        logger.error(err)
    }
}
</script>

<style lang="scss" scoped>
@forward '@/styles/new_styles.scss';

.ag-theme-quartz {
    --ag-checkbox-checked-color: #2a79d2;
    --ag-active-color: #ffffff;
    --ag-font-family: 'Geist', sans-serif;
    --ag-font-size: 14px;

    :deep(.ag-header-cell-text) {
        font-family: 'Geist', sans-serif;
        font-weight: 800;
    }

    :deep(.ag-cell) {
        font-family: 'Geist', sans-serif;
    }

    :deep(.ag-checkbox-input-wrapper) {
        font-size: 18px !important;
    }
}

:deep(.not-pruned) path {
    fill: vars.$grey04 !important;
}

:deep(.pruned) path {
    fill: vars.$success !important;
}

.patent-list {
    &.inline {
        position: static !important;
        left: 0 !important;
        top: 0 !important;

        transform: translate(0, 0) !important;

        :deep(.a-modal-close) {
            display: none !important;
            pointer-events: none !important;
        }

        :deep(.background) {
            display: none !important;
        }

        :deep(.aon-container) {
            height: 100% !important;
        }

        :deep(.aon-row) {
            height: 100% !important;
        }

        :deep(.aon-col) {
            height: 100% !important;
        }

        :deep(.container) {
            box-shadow: none !important;

            .patent-list-header {
                display: none !important;
            }
        }

        :deep(.aon-col-10) {
            padding: 0 !important;
        }
    }

    .bulk-action {
        padding: 10px;
        padding-bottom: 8px;
        border-radius: vars.$border-radius * 2;
        cursor: pointer;

        transition: all 0.3s ease-in-out;

        &:hover {
            background: vars.$grey07;
        }
    }

    .total-holder {
        position: absolute;
        bottom: 30px;
        left: 30px;

        .total {
            border-right: 2px solid vars.$grey03;
        }
    }

    &.absolute {
        position: absolute !important;
    }

    z-index: 11 !important;

    :deep(.a-modal-close) {
        background: vars.$grey04;
        transform: scale(1.2) !important;
        top: 20px;
        right: 20px;

        svg {
            color: vars.$grey02 !important;
        }
    }

    :deep(.offset-1) {
        margin-left: 0 !important;
    }

    :deep(.aon-col) {
        padding: 10px !important;
    }

    :deep(.aon-col-10) {
        flex: 0 0 100%;
        max-width: 100%;
    }

    :deep(.container) {
        width: 100% !important;
        height: 100% !important;
        padding: 0 !important;
        max-height: none !important;
        box-shadow: 0px 0px 35px 10px rgba(0, 0, 0, 0.35) !important;
    }

    :deep(.modal-content) {
        height: 100% !important;
    }

    :deep(.background) {
        width: 110%;
        height: 110%;
        top: -3px;
        left: -3px;
    }

    .patent-list-header {
        min-height: 75px;
        width: 100%;

        .source-display {
            height: 100%;
        }
    }

    .divide {
        width: 2px;
        height: 20px;
        background-color: vars.$grey04;
    }
}
</style>
