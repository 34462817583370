<template>
    <div class="portfolio-manager-tab">
        <AonContainer>
            <AonRow class="mt-4">
                <AonSpinner
                    v-if="entityStore.loadingEntity || !entityStore.entity"
                    class="ma-auto"
                    :scale="0.5"
                    color="castleMoat"
                />
                <div v-else class="grid-holder w-full">
                    <AonCol class="aon-col-12 pa-0">
                        <div class="tab-cal-holder d-flex justify-content-between w-full">
                            <AonTabs
                                class="light"
                                :tabs="portfolioTabs"
                                :current-tab="currentTab"
                                @tab-change="tabChange"
                            />
                            <MoatDatePickerRange v-show="currentTab === 1" />
                        </div>
                    </AonCol>
                    <KeepAlive>
                        <Overview v-if="currentTab === 1" @cell-clicked="onCellClicked" />
                    </KeepAlive>
                    <KeepAlive>
                        <Groom v-if="currentTab === 2" :passed-filters="passedFilters" />
                    </KeepAlive>
                </div>
            </AonRow>
        </AonContainer>
    </div>
</template>

<script setup>
import { computed, ref, watchEffect, nextTick } from 'vue'
import { useEntityStore } from '@/stores'

import Overview from '@/components/company/proTabs/portfolioManager/Overview.vue'
import Groom from '@/components/company/proTabs/portfolioManager/Groom.vue'
import MoatDatePickerRange from '@/components/company/proTabs/helpers/MoatDatePickerRange.vue'

const entityStore = useEntityStore()

const currentTab = ref(1)
const portfolioTabs = ref([
    {
        text: 'Overview',
        value: 'overview',
        id: 1,
    },
    {
        text: 'Groom Portfolio',
        value: 'groom',
        id: 2,
    },
])
const passedFilters = ref(null)

const tabChange = (tab) => {
    currentTab.value = tab.id
}

const onCellClicked = async (data) => {
    // currentTab.value = 2
    // await nextTick()
    // passedFilters.value = data
}
</script>
<style lang="scss" scoped>
.portfolio-manager-tab {
    width: 100%;
}
</style>
