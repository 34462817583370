import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

const formatDate = (date) => {
    return new Date(date).toISOString().split('T')[0]
}

export const usePortfolioManagerStore = defineStore('portfolioManagerStore', () => {
    const today = new Date()
    const endOfYear = new Date(today.getFullYear(), 11, 31)
    const dateRange = ref([today, endOfYear])

    const resetState = () => {
        dateRange.value = null
    }

    return {
        dateRange,
        resetState,
    }
})
